import React from "react"
import "./styles.css"

const PageHeader = (props) => {
  const { title, subtitle } = props

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="page-header">{title}</h2>
        </div>
        {subtitle && 
          <div className="col-12 col-md-8">
            <h4 className="page-subheader">{subtitle}</h4>
          </div>
        }
      </div>
    </div>
  )

}

export default PageHeader