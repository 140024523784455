import React from "react"
import "./styles.css"

const SectionHeader = (props) => {
  const { header, onlyHeader, isLarge } = props

  if (onlyHeader) { 
    return <h3>{header}</h3>
  }

  return (
    <div className={`container section-header ${isLarge ? 'large' : ''}`} >
      <div className="row">
        <div className="col-12">
          <h3>{ header }</h3>
        </div>
      </div>
    </div>
  )

}

export default SectionHeader