import React from "react"
import { NavLink, Outlet } from "react-router-dom";

import MobileNav from "./MobileNav";
import DestktopNav from "./DesktopNav";

const navItems = [
  <NavLink onClick={() => scrollToTop()} exact to="/">Start</NavLink>,
  <NavLink onClick={() => scrollToTop()} to="previousjobs">Tidigare uppdrag</NavLink>,
  <NavLink onClick={() => scrollToTop()} to="sustainability">Hållbarhet</NavLink>,
  <NavLink onClick={() => scrollToTop()} to="sponsorship">Sponsring</NavLink>
]

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
}

const Menu = (props) => {
  const { screenSize } = props
  const mobileWidth = 768
  const content = screenSize.width < mobileWidth ? <MobileNav navItems={navItems} /> : <DestktopNav navItems={navItems}/>

  return (
    <>
      { content }
      <Outlet />
    </>
  );
}

export default Menu;
