import React from "react"
import { Link } from "react-router-dom";
import "./styles.css"

import IconGrey from "../../../../assets/icon-grey.png"

const DestktopNav = (props) => {
  const { navItems } = props

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }

  return (
    <>
      <div className="container-fluid desktop-nav">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <Link to="/">
                <img className="nav-icon" alt="company-logo" onClick={() => scrollToTop()} src={IconGrey} />
              </Link>
            </div>
              <div className="col-8 nav-items-wrapper">
                {navItems}
              </div>
          </div>
        </div>
      </div>
      <div className="desktop-nav-height"></div>
    </>
  )
}

export default DestktopNav