import React from "react"
import PageHeader from "../../shared/components/PageHeader"
import Block33 from "../../shared/components/Block33"
import PreviousJob from "./PreviousJob"

import hammarbysjostad1 from "../../assets/hammarbysjostad1.png"
import bjorkhagen1 from "../../assets/bjorkhagen1.png"
import vasastan1 from "../../assets/vasastan1.png"
import sodermalm1 from "../../assets/sodermalm1.png"
import sodermalm2 from "../../assets/sodermalm2.png"
import sodermalm3 from "../../assets/sodermalm3.png"
import sodermalm4 from "../../assets/sodermalm4.png"
import sodermalm5 from "../../assets/sodermalm5.png"
import vasastan2 from "../../assets/vasastan2.png"


import texts from "./texts.json"
import "./styles.css"

const { title, previousJobs } = texts

const PreviousJobs = () => {
return (
  <>
    <div className="space-vertical-lg bg border-bottom">
        <PageHeader title={title} />
    </div>
    <div className="container previous-jobs">
      <div className="row">
          <Block33
            contentFirst={
              <PreviousJob 
                title={previousJobs[0].title}
                img={sodermalm5}
                text={previousJobs[0].text}
              />
            }
            contentSecond={
              <PreviousJob 
                title={previousJobs[1].title}
                img={vasastan2}
                text={previousJobs[1].text}
              />
            }
            contentThird={
              <PreviousJob 
                title={previousJobs[2].title}
                img={hammarbysjostad1}
                text={previousJobs[2].text}
              />
            }
          />
          <Block33
            contentFirst={
              <PreviousJob 
              title={previousJobs[3].title}
              img={bjorkhagen1}
              text={previousJobs[3].text}
            />
              
            }
            contentSecond={
              <PreviousJob 
                title={previousJobs[4].title}
                img={vasastan1}
                text={previousJobs[4].text}
              />
            }
            contentThird={
              <PreviousJob 
                title={previousJobs[5].title}
                img={sodermalm1}
                text={previousJobs[5].text}
              />
            }
          />
          <Block33
            contentFirst={
              <PreviousJob 
                title={previousJobs[6].title}
                img={sodermalm2}
                text={previousJobs[6].text}
              />
            }
            contentSecond={
              <PreviousJob 
                title={previousJobs[7].title}
                img={sodermalm3}
                text={previousJobs[7].text}
              />
            }
            contentThird={
              <PreviousJob 
                title={previousJobs[8].title}
                img={sodermalm4}
                text={previousJobs[8].text}
              />
            }
          />
      </div>
    </div>
  </>
  )
}

export default PreviousJobs