import React from "react"
import "./styles.css"

const TitleWithText = (props) => {

  const { title, text } = props

  const Texts = text.map((item, index) => {
    return (<p key={index}>{item}</p>)
  })

  return (
    <div className="container title-with-text-block">
      <div className="row">
        <div className="col-12 col-md-10">
          <div className="wrapper">
            <h4>{title}</h4>
            {Texts}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleWithText