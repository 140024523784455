import React from "react"

export const paragraphWithNewlines = (unformattedText) => {
  return unformattedText.split('\n').map(text => <p>{text}</p>)
}

export const getCurrentWindowDimension = () => {
  return {
      width: window.innerWidth,
      height: window.innerHeight
  }
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
}