import React from "react"
import "./styles.css"

const Block6633 = (props) => {
  const { content66, content33 } = props

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-8">{content66}</div>
        <div className="col-12 col-md-4">{content33}</div>
      </div>
    </div>
  )
}

export default Block6633