import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { HashRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import PreviousJobs from './pages/PreviousJobs';
import Sustainability from './pages/Sustainability';
import Sponsorship from './pages/Sponsorship';
import Home from './pages/Home';
import OptIn from './pages/OptIn';
import Policy from './pages/Policy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="previousjobs" exact element={<PreviousJobs />} />
        <Route path="sustainability" element={<Sustainability />} />
        <Route path="sponsorship" element={<Sponsorship />} />
        <Route path="optin" element={<OptIn />} />
        <Route path="policy" element={<Policy />} />
      </Route>
    </Routes>
  </HashRouter>
);
