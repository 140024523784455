import React from "react"
import PageHeader from "../../shared/components/PageHeader"
import TitleWithText from "../../shared/components/TitleWithText"

import texts from "./texts.json"

const { title, content } = texts

const Sustainability = () => {
  return ( 
    <>
      <div className="space-vertical-lg bg border-bottom">
        <PageHeader title={title} />
      </div>

      <div className="space-top-md">
        {content.map(item => (<TitleWithText title={item.title} text={item.texts} />))}
      </div>
    </>
  )
}

export default Sustainability