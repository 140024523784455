import React from "react"
import './styles.css'
import texts from "./texts.json"
import SectionHeader from "../../../../shared/components/SectionHeader"

const { title, services } = texts

const Services = () => {

  return (
    <div className="container-fluid bg border-bottom border-top space-vertical-md">
      <SectionHeader header={title} isLarge />
      <div className="container">
        <div className="row">
            {services.map((service, index) => {
              const {title, description} = service

              return (
                <div className="col-12 col-sm-6 col-md-4" key={index}>
                  <div className="service-wrapper">
                    <h4>{title}</h4>
                    <p>{description}</p>
                  </div>
                </div>
              )
            })}
          
        </div>
      </div>
    </div>
  );
}

export default Services