import React from "react"
import "./styles.css"

const PreviousJob = (props) => {

  const { title, img, text} = props

  return (
    <div className="previous-job">
      <h3>{title}</h3>
      <div class="image" style={{ backgroundImage: `url(${img})` }}></div>
      <p>{text}</p>
    </div>
  )
}

export default PreviousJob