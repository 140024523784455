import React from "react"
import "./styles.css"

const VideoBackground = (props) => {
  const { videoSource, fallbackImg } = props



  return ( 
    <div className="video-bg-wrapper">
      <video autoplay="autoplay" muted="muted" loop="loop" poster={fallbackImg}>
        <source src={videoSource} type="video/webm" />
      </video>
    </div>
  )
}

export default VideoBackground