import React from "react"
import PageHeader from "../../shared/components/PageHeader"
import SectionHeader from "../../shared/components/SectionHeader"
import TextBlock from "../../shared/components/TextBlock"
import { paragraphWithNewlines } from "../../shared/helpers"

import texts from "./texts.json"

const { title, subtitle, sustainabilityItems } = texts

const Policy = () => {
  return ( 
    <>
      <div className="space-vertical-lg bg border-bottom">
        <PageHeader title={title} subtitle={subtitle} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={sustainabilityItems[0].title} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[0].texts[0])} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[0].texts[1])} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={sustainabilityItems[1].title} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[1].texts[0])} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[1].texts[1])} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={sustainabilityItems[2].title} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[2].texts[0])} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[2].texts[1])} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={sustainabilityItems[3].title} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[3].texts[0])} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[3].texts[1])} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={sustainabilityItems[4].title} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[4].texts[0])} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[4].texts[1])} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={sustainabilityItems[5].title} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[5].texts[0])} />
        <TextBlock text={paragraphWithNewlines(sustainabilityItems[5].texts[1])} />
      </div>
    </>
  )
}

export default Policy