import React, { useState } from "react"
import { Link } from "react-router-dom";
import IconBurger from "../../../../assets/burger.png"
import IconGrey from "../../../../assets/icon-grey.png"
import IconClose from "../../../../assets/icon-close.png"
import "./styles.css"


const MobileNav = (props) => {
  const { navItems } = props
  const [isOpen, setIsOpen] = useState(false)

  const content = (
    <div className="items-wrapper">
      <ul>
        {navItems.map(navItem => {
          return (
            <li onClick={() => setIsOpen(false)}>
              {navItem}
            </li>
          )
        })}
      </ul>
    </div>
  )

  return (
    <>
      <div className={`container-fluid mobile-nav ${!isOpen ? '' : 'open'}`}>
        <div className="row">
          <div className="col-6">
            <Link to="/">
              <img className="nav-icon" alt="logo" onClick={ () => setIsOpen(false) } src={IconGrey} />
            </Link>
          </div>
          <div className="col-6">
            <div className="burger-wrapper">
              <button className="burger" onClick={ () => setIsOpen(!isOpen) }>{!isOpen ? <img alt="open menu icon" className="menu-open" src={IconBurger} /> : <img alt="close menu icon" className="menu-close" src={IconClose} />}</button>  
            </div>
          </div>
          <div className="col-12">
            <div className={`nav-content ${!isOpen ? '' : 'open'}`}>
              { !isOpen ? '' : content }
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-nav-height"></div>
    </>
  )
}

export default MobileNav