import React from "react"
import "./styles.css"

const TextBlock = (props) => {

  const { text } = props

  return (
    <div className="container text-block">
      <div className="row">
        <div className="col-12 col-md-10">
          <div className="wrapper">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextBlock