import React, { useState, useEffect } from "react"
import Menu from "./shared/components/Menu"
import Footer from './shared/components/Footer'
import { getCurrentWindowDimension, scrollToTop } from "./shared/helpers"
import { NavLink } from "react-router-dom"

import CookieConsent from "react-cookie-consent"

function App() {
  const [screenSize, setScreenSize] = useState(getCurrentWindowDimension())

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentWindowDimension())
    }
    window.addEventListener('resize', updateDimension)
    
    return(() => {
        window.removeEventListener('resize', updateDimension)
    })
  }, [screenSize])

  return (
    <div className="App">
      <CookieConsent
        buttonText="Jag förstår"
        style={
          { 
            backgroundColor: "#f9f9f9",
            borderTop: "2px solid #383838",
            color: "#000"
          }
        }
        buttonStyle={
          {
            backgroundColor: "#fff",
            border: "2px solid #383838"
          }
        }
      >
        Denna webbplats använder cookies för att förbättra användarupplevelsen.
        Genom att fortsätta använda denna webbplats accepterar du detta. Du kan läsa mer om vår policy <NavLink onClick={() => scrollToTop()} to="/policy">här</NavLink>.
      </CookieConsent>
      <Menu screenSize={screenSize} />
      <Footer screenSize={screenSize} />
    </div>
  );
}

export default App;
