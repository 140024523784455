import React from "react"
import TextBlock from "../../../../shared/components/TextBlock"
import SectionHeader from "../../../../shared/components/SectionHeader"
import PageHeader from "../../../../shared/components/PageHeader"
import { paragraphWithNewlines } from "../../../../shared/helpers"
import texts from "./texts.json"
import './styles.css'

const { p1, p2, p3, p4, p5 } = texts

const Intro = () => {

  return (
    <>
      <div className="container-fluid space-vertical-lg bg border-bottom">
        <PageHeader title={p1.title} subtitle={p1.description} />
      </div>

      <div className="container-fluid space-top-md">
        <SectionHeader header={p2.title} />
        <TextBlock text={p2.description} />
      </div>

      <div className="container-fluid space-top-md">
        <SectionHeader header={p3.title} />
        <TextBlock text={p3.description} />
      </div>

      <div className="container-fluid space-top-md">
        <SectionHeader header={p4.title} />
        <TextBlock text={p4.description} />
      </div>

      <div className="container-fluid space-top-sm space-bottom-md">
        <TextBlock text={paragraphWithNewlines(p5.description)} />
      </div>
    </>
  );
}

export default Intro