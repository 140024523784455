import React from "react"
import "./styles.css"

const Block33 = (props) => {
  const {title, contentFirst, contentSecond, contentThird} = props

  return ( 
    <div className="container-fluid">
      <div className="container no-padding">
        { title &&
          <div className="row">
            <div className="col-12">
              <h3>{title}</h3>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">{contentFirst}</div>

          {contentSecond &&
            <div className="col-12 col-md-6 col-lg-4">{contentSecond}</div>
          }

          {contentThird &&
            <div className="col-12 col-md-6 col-lg-4">{contentThird}</div>
          }
        </div>
      </div>
    </div>
  )
}

export default Block33