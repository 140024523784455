import React from "react"
import Block3366 from "../../../../shared/components/Block3366"
import Block6633 from "../../../../shared/components/Block6633"
import SectionHeader from "../../../../shared/components/SectionHeader"
import { paragraphWithNewlines } from "../../../../shared/helpers"
import texts from "./texts.json"
import "./styles.css"

import profilePic from "../../../../assets/johan.png"
import companyPic from "../../../../assets/logo-text-grey.png"

const { p1, p2 } = texts

const imgProfilePic = <img className="profile-pic" src={profilePic} alt="Johan" />
const imgCompanyPic = <img className="company-pic" src={companyPic} alt="company logo" />

const p1content = (
  <div className="center-y">
    <div>
      <SectionHeader onlyHeader header={p1.title} />  
      <p>{p1.description}</p>
    </div>
  </div>
)
const p2TextWithNewline = paragraphWithNewlines(p2.description)
const p2content = p2TextWithNewline

const p2CompanyPic = (
  <div className="center-x-y">
      {imgCompanyPic}
  </div>
)

const About = () => {

  return (
    <div className="about">
      <Block3366 content33={imgProfilePic} content66={p1content}/> 
      <div className="spacer"></div>
      <SectionHeader header={p2.title} />
      <Block6633 content33={p2CompanyPic} content66={p2content} />
    </div>
  );
}

export default About