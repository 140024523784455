import React from "react"
import SectionHeader from "../SectionHeader"
import ImageMap from "../../../assets/map.png"
import LogoLinkedIn from "../../../assets/logo-linkedin.webp"
import "./styles.css"

const Footer = () => {
  return ( 
    <div className="container-fluid footer bg border-top space-top-md">
      <SectionHeader isLarge header={"Kontakt"} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <p><span>Epost</span> johan@ekwallsvvs.se</p>
            <p><span>Telefon</span> +4670 739 08 17</p>
            <p>
              <span>Address</span> 
              Sickla Industriväg 26<br />
              131 55 <br />
              Nacka
            </p>
            <p>
              <span>Följ oss gärna</span>
            </p>
            <a href="https://www.linkedin.com/company/ekwalls-vvs-design-ab/?viewAsMember=true" target="blank_" rel="noreferrer">
              <img className="social-logo" src={LogoLinkedIn} alt="linkedin logo link" />
            </a>
          </div>
          <div className="col-12 col-md-6">
            <iframe className="image-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2036.6618452075622!2d18.116534771349563!3d59.305204630921025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f7812f154b9c5%3A0xcf485d395ea71733!2sSickla%20industriv%C3%A4g%2026%2C%20131%2055%20Nacka!5e0!3m2!1sen!2sse!4v1707662040060!5m2!1sen!2sse" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer