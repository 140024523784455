import React from "react"
import VideoBackground from "../../shared/components/VideoBackground"
import videoBG from "../../assets/bg.webm"
import Intro from "./sections/Intro"
import Services from "./sections/Services"
import About from "./sections/About"
import HeroFallbackImg from "../../assets/seatown.jpg"
import './styles.css'

import LogoTextWhite from "../../assets/logo-text-white.png"

const Home = () => {
  return (
    <>
      <div className="hero-block-wrapper">
        <div className="container-fluid hero-block-bg">
          <VideoBackground videoSource={videoBG} fallbackImg={HeroFallbackImg} />
        </div>
        <div className="container-fluid hero-block">
          <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="logo-wrapper">
                    <img className="hero-logo" alt="company logo" src={LogoTextWhite} />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <Intro />
      <Services />
      <About />
    </>
  );
}

export default Home