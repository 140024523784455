import React from "react"
import PageHeader from "../../shared/components/PageHeader"
import SectionHeader from "../../shared/components/SectionHeader"
import TextBlock from "../../shared/components/TextBlock"
import { paragraphWithNewlines, scrollToTop } from "../../shared/helpers"

import { NavLink } from "react-router-dom"

const pageTitle = "Få en kostnadsfri genomgång"

const pageSubtitle = `
Få en konstnadsfri genomgång med en erfaren VVS-konsult som hjälper dig att energieffektivisera din fastighet och sänka elräkningen.\n
Genomgången sker i form an ett 30-60 minuter långt telefonsamtal eller fysiskt platsbesök beroende på storlek av fastighet.\n
Få reda på vilket ventilations- och/eller värmesystem som lämpar sig bäst åt din fastighet.\n
Anmäl dig nedan så kontaktar vi dig.`

const title = "Stressad över kommande prishöjningar gällande el- och fjärrvärme?"
const titlePt2 = "- Få en konstnadsfri genomgång idag!"

const subtitle = 
  `Få en konstnadsfri genomgång med en erfaren VVS-konsult som hjälper dig att energieffektivisera din fastighet och sänka elräkningen.\n
  Genomgången sker i form an ett 30-60 minuter långt telefonsamtal eller fysiskt platsbesök beroende på storlek av fastighet.\n
  Få reda på vilket ventilations- och/eller värmesystem som lämpar sig bäst åt din fastighet.\n
  Anmäl dig nedan så kontaktar vi dig`

const whyTitle = "Varför skall du byta ventilations- och/eller värmesystem?"
const whyContent = 
`	- Stor energibesparing och snabb payback\n
- Hög komfort
- Minska risken för hälsosjukdomar
- Ökar produktivitet och prestation
- Bättre klimat inomhus
- Sänker ditt elpris
- Högre fastighetsvärde
- Hållbart
- Lättare service
- Minskad underhållskostnad
- Följ förbrukning i realtid
- Driftsäkerhet
- Hög kundnöjdhet
`

const didYouKnowTitle = "Visste du att ..."
const didYouKnowContent =
  `- 40% av all den energi vi gör av med i Sverige går åt att värma upp fastigheter?\n
  - Vattenskador kostar i snitt 10 miljarder varje år? Bidra inte till den siffran!
  `

const test = 
  <>
    <p>Få en konstnadsfri genomgång med en erfaren VVS-konsult som hjälper dig att energieffektivisera din fastighet och sänka elräkningen.</p>
    <br />
    <p>Genomgången sker i form av ett 30-60 minuter långt telefonsamtal eller fysiskt platsbesök beroende på storlek av fastighet.</p>
    <br />
    <p>Anmäl dig nedan så kontaktar vi dig!</p>
  </>

const OptIn = () => {
  return (
    <>
      <div className="space-vertical-lg bg border-bottom">
        <PageHeader title={pageTitle} subtitle={test} />
      </div>

      <div className="container space-top-lg">
        <div className="row">
          <div className="col-12" align="center">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeT6oQjUxFsSrjpcfy0QrGlz1h_0voW_AWUoMp24QXv3xRRNw/viewform?embedded=true" width="640" height="680" frameborder="0" marginheight="0" marginwidth="0" title="form">Läser in …</iframe>
          </div>
        </div>
      </div>

      <div className="space-top-lg">
        <SectionHeader header={whyTitle} />
        <TextBlock text={paragraphWithNewlines(whyContent)} />
      </div>

      <div className="space-top-md">
        <SectionHeader header={didYouKnowTitle} />
        <TextBlock text={paragraphWithNewlines(didYouKnowContent)} />
      </div>

      <div className="space-top-lg">
        <div className="container text-block">
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="wrapper">
                <p>Läs mer om hur vi hanterar era personuppgifter på vår <a onClick={() => scrollToTop()} href="#/policy">policy-sida</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OptIn